import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Title = styled('h4')({
  fontSize: '14px',
  fontWeight: 700,
  margin: 0,
});

const Column = styled('div')(({ theme }) => ({
  margin: `${theme.spacing(0.5)} 0`,
}));

const NavigationList = styled('ul')({
  margin: 0,
  padding: 0,
  listStyle: 'none',
  fontSize: '14px',
});

const StyledHeading = styled(Typography)({
  fontSize: '14px',
  fontWeight: 500,
  padding: '2px 0',
});

const BottomNavigation = styled('div')(({ theme }) => ({
  display: 'flex',
  padding: `${theme.spacing(3)} 0`,
  width: '100%',
  backgroundColor: 'white',
}));

const NavigationColumns = styled('div')(({ theme }) => ({
  marginRight: theme.spacing(1),
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    display: 'block',
  },
}));

const ListItem = styled('li')(({ theme }) => ({
  a: {
    color: theme.palette.text.primary,
    paddingTop: theme.spacing(1),
    display: 'block',
    lineHeight: 1.15,
    textDecoration: 'none',
    '&:hover': {
      color: 'rgb(151, 151, 151)',
    },
  },
}));

const PhoneNumber = styled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.primary,
  transition: 'color 0.25s',
  ['&:hover']: {
    color: 'rgb(116, 196, 127)',
  },
}));

const MobileAccordion = styled(Accordion)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
}));

const DesktopColumn = styled(Column)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const NavigationListItem = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  const isIntern = href.includes('smava.de') || href.startsWith('/');
  return (
    <ListItem>
      <Link href={href} rel={isIntern ? '' : 'noreferrer'}>
        {children}
      </Link>
    </ListItem>
  );
};

interface NavItemProps {
  heading: string;
  children?: React.ReactNode;
}

const NavItem: React.FC<NavItemProps> = ({ heading, children }) => {
  return (
    <>
      <MobileAccordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />}>
          <StyledHeading>{heading}</StyledHeading>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </MobileAccordion>
      <DesktopColumn>
        <Title>{heading}</Title>
        {children}
      </DesktopColumn>
    </>
  );
};

function BottomNavigationMap() {
  return (
    <BottomNavigation>
      <Container>
        <NavigationColumns>
          <NavItem heading="Unternehmen">
            <NavigationList>
              <NavigationListItem href="https://www.smava.de/ueber-smava/">
                Über smava
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/jobs/">
                Jobs
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/presse/">
                Presse
              </NavigationListItem>
              <NavigationListItem href="https://smava.zendesk.com/hc/de">
                Hilfe
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/kredit/partner/">
                Unsere Partner
              </NavigationListItem>
              <NavigationListItem href="/kontakt">Kontakt</NavigationListItem>
              <NavigationListItem href="/dsa-beschwerdeverfahren">
                DSA Beschwerdeverfahren
              </NavigationListItem>
            </NavigationList>
          </NavItem>
          <NavItem heading="Kredit aufnehmen">
            <NavigationList>
              <NavigationListItem href="https://www.smava.de/kreditvergleich/">
                Kreditvergleich
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/privatkredit/">
                Privatkredit
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/autokredit/">
                Autokredit
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/kredit/">
                Kredit
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/umschuldung">
                Umschuldung
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/kredit/rechner">
                Kreditrechner
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/kredit/kreditarten/">
                Kreditkarten
              </NavigationListItem>
            </NavigationList>
          </NavItem>
          <NavItem heading="Service">
            <NavigationList>
              <NavigationListItem href="https://www.smava.de/blog/">
                smava Blog
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/blog/archiv/">
                smava Blog Archiv
              </NavigationListItem>
              <NavigationListItem href="https://www.facebook.com/smava.de">
                smava bei Facebook
              </NavigationListItem>
              <NavigationListItem href="https://twitter.com/smava">
                smava bei Twitter
              </NavigationListItem>
              <NavigationListItem href="https://www.smava.de/partnerprogramm/">
                Kredit Partnerprogramm
              </NavigationListItem>
              <NavigationListItem href="https://smava.aklamio.com/">
                Freunde werben Freunde
              </NavigationListItem>
            </NavigationList>
          </NavItem>
          <NavItem heading="Kostenlose Beratung">
            <Typography fontSize="14px" variant="body2">
              Wir beraten Sie gerne telefonisch unter:
              <br />
              <PhoneNumber href="tel:0800 000 98 00">
                0800 000 98 00
              </PhoneNumber>
              <br />
              <br />
              Montag - Freitag: 8:00 - 20:00 Uhr
              <br />
              Samstag: 10:00 - 15:00 Uhr
            </Typography>
          </NavItem>
        </NavigationColumns>
      </Container>
    </BottomNavigation>
  );
}

export default BottomNavigationMap;
